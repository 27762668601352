import React, { useState, useEffect } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup
} from "react-simple-maps";
import { Tooltip } from 'react-tooltip'; // Import the library

//original file with different country names
//const geoUrl ="https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries-sans-antarctica.json";

//new, manipulated file
const geoUrl = "/world-countries-sans-antarctica.json";

  function csvToArray(str, delimiter = ",") {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    // Map the rows
    // split values from each row into an array
    // use headers.reduce to create an object
    // object properties derived from headers:values
    // the object passed as an element of the array
    const arr = rows.map(function (row) {
      const values = row.split(delimiter);
      const el = headers.reduce(function (object, header, index) {
        object[header] = values[index];
        return object;
      }, {});
      return el;
    });

    // return the array
    return arr;
  }

const results = []

function MapChart(){
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [unavailableCountries, setUnavailableCountries] = useState([]);
    const [potentialCountries, setPotentialCountries] = useState([]);
    const [questionableCountries, setQuestionableCountries] = useState([]);
    const [inaccessibleCountries, setInaccessibleCountries] = useState([]);
    const [availableCountries, setAvailableCountries] = useState([]);
    const [countryVisas, setCountryVisas]  = useState([]);
    useEffect(()=>{

        fetch('passports.json')
        .then(response => response.json())
        .then((text) => {
            setCountryVisas(text)
        })


    }, []) // <-- empty dependency array    


  const checkAvailableCountries = (countries) => {
    var red = []
    var orange = []
    var yellow = []
    var green = []
    var black = []
    for (var country of countries) {
        countryVisas[country]['red'].forEach(function(value){
            if (red.indexOf(value)===-1) red.push(value);
        })
        countryVisas[country]['orange'].forEach(function(value){
            if (orange.indexOf(value)===-1) orange.push(value);
        })
        countryVisas[country]['yellow'].forEach(function(value){
            if (yellow.indexOf(value)===-1) yellow.push(value);
        })
        countryVisas[country]['green'].forEach(function(value){
            if (green.indexOf(value)===-1) green.push(value);
        })
        countryVisas[country]['black'].forEach(function(value){
            if (black.indexOf(value)===-1) black.push(value);
        })
    }
    const unavailable = red.filter( ( el ) => !black.includes ( el ));
    setUnavailableCountries(unavailable)
    const questionable = orange.filter( ( el ) => !red.includes( el ) && !black.includes ( el ) );
    setQuestionableCountries(questionable)
    const potential = yellow.filter( ( el ) => !red.includes( el ) && !orange.includes( el ) && !black.includes ( el ));
    setPotentialCountries(potential)
    const selected = green.filter( ( el ) => !red.includes( el ) && !orange.includes( el ) && !yellow.includes( el )  && !black.includes ( el ));
    setAvailableCountries(selected)
    setInaccessibleCountries(black)

    window.unavailable = unavailable.concat(black)
    window.questionable = questionable.concat(potential)
    window.visafree = selected
    //update the bottom left box
    document.getElementById("selectedCountriesCount").textContent = countries.length;
    document.getElementById("greenCountries").textContent = selected.length;
    document.getElementById("yellowCountries").textContent = potential.length + questionable.length;
    //document.getElementById("orangeCountries").textContent = questionable.length;
    document.getElementById("redCountries").textContent = unavailable.length + black.length;
    //document.getElementById("blackCountries").textContent = black.length;

  }

  const handleCountryClick = (geography) => {
    const { name } = geography.properties;
    var newCountries = []
    if (selectedCountries.includes(name)) {
        newCountries = selectedCountries.filter((c) => c !== name)

    } else {
      newCountries = [...selectedCountries, name];
    }
    setSelectedCountries(newCountries);

    //put the selected countries into the global variable
    var selected_passports = selectedCountries
    selected_passports.push(geography.properties.name)
    window.selected_passports = selectedCountries;

    checkAvailableCountries(newCountries)

  };

  return (
    <div>
    <ComposableMap projection="geoMercator">
      <defs>
        <pattern id="dotsLight" patternUnits="userSpaceOnUse" width="4" height="4">
          <image href="/dotsLight.svg" x="0" y="0" width="4" height="4" />
        </pattern>
        <pattern id="dotsMedium" patternUnits="userSpaceOnUse" width="4" height="4">
          <image href="/dotsMedium.svg" x="0" y="0" width="4" height="4" />
        </pattern>
        <pattern id="dotsDark" patternUnits="userSpaceOnUse" width="4" height="4">
          <image href="/dotsDark.svg" x="0" y="0" width="4" height="4" />
        </pattern>
      </defs>

      <ZoomableGroup center={[0, 0]} zoom={1}>
      <Geographies
        geography={geoUrl}>

        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              onClick={() => handleCountryClick(geo)}
              fill="var(--country-basic)"
              stroke="var(--borders)"
              strokeWidth={0.5}
              data-tip={geo.properties.name} // Add this line for tooltip content


              style={{
                default: {
                    fill: selectedCountries.includes(geo.properties.name) ? (
                          availableCountries.includes(geo.properties.name) ? 'url(#dotsLight) var(--c1)'
                        : questionableCountries.includes(geo.properties.name) ? 'url(#dotsMedium) var(--c2)'
                        : potentialCountries.includes(geo.properties.name) ? 'url(#dotsMedium) var(--c2)'
                        : unavailableCountries.includes(geo.properties.name) ? 'url(#dotsDark) var(--c3)'
                        : inaccessibleCountries.includes(geo.properties.name) ? 'url(#dotsDark) var(--c3)':'url(#dotsLight) var(--c1)')
                    : availableCountries.includes(geo.properties.name) ? 'var(--c1)'
                    : questionableCountries.includes(geo.properties.name) ? 'var(--c2)'
                    : potentialCountries.includes(geo.properties.name) ? 'var(--c2)'
                    : unavailableCountries.includes(geo.properties.name) ? 'var(--c3)'
                    : inaccessibleCountries.includes(geo.properties.name) ? 'var(--c3)'
                    :'var(--main)',

                    /*
                    fill: selectedCountries.includes(geo.properties.name) ? (
                          availableCountries.includes(geo.properties.name) ? 'var(--c1c)'
                        : questionableCountries.includes(geo.properties.name) ? 'var(--c2c)'
                        : potentialCountries.includes(geo.properties.name) ? 'var(--c3c)'
                        : unavailableCountries.includes(geo.properties.name) ? 'var(--c4c)'
                        : inaccessibleCountries.includes(geo.properties.name) ? 'var(--c5c)':'var(--c1c)')
                    : availableCountries.includes(geo.properties.name) ? 'var(--c1)'
                    : questionableCountries.includes(geo.properties.name) ? 'var(--c2)'
                    : potentialCountries.includes(geo.properties.name) ? 'var(--c3)'
                    : unavailableCountries.includes(geo.properties.name) ? 'var(--c4)'
                    : inaccessibleCountries.includes(geo.properties.name) ? 'var(--c5)'
                    :'var(--main)',
                    */
                  outline: 'none',
                },
                hover: {
                  fill: "#347D39",
                  outline: "none"
                },
                pressed: {
                  fill: "#347D39",
                  outline: "none"
                }
              }}
            />
          ))
        }
      </Geographies>
      </ZoomableGroup>
    </ComposableMap>
    <Tooltip 
      id="tooltipjan"
      effect="solid" 
      place="top" 
      offset={{ top: 100, left: 100 }}
      style={{ zIndex: 10000 }}/>
    </div>

  );
};

export default MapChart;

