import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { Tooltip } from 'react-tooltip';

import "./styles.css";

import ClickableMap from "./ClickableMap";

function App() {
  useEffect(() => {
    // Initialize Google Analytics with your tracking ID
    ReactGA.initialize('UA-262477120-1');
    ReactGA.set({ debug: true })
    // Send a pageview hit to Google Analytics for the initial page load
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Define a global variable
    window.selected_passports = [];
    console.log("global variable from useEffect ", window.selected_passports)
  }, []);
  return (
    <div>
      <ClickableMap />
      <Tooltip id="tooltip" />
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
